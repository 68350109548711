@import "noe";
@import "media-queries";

/*** WINDOWS / LINUX COMPATIBILITY ***/

/*** Ant font display Fixes between Windows and Linux browsing ***/
/* in headers */
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: unset;
}

/* in tabs headers */
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: unset;
}

/**** DATE SELECTION PANEL ****/

html {
  .ant-date-picker-custom-props(@disabledColor, @disabledText, @disabledHoverColor,@disabledTextHover) {
    .ant-picker-dropdown td:not(.ant-picker-cell-selected) {
      &:not(.ant-picker-cell-in-range) {
        &:not([class*="ant-picker-cell-range-"]):has(.disabled-date) {
          border-radius: 2px !important;
          color: @disabledText;

          &::before {
            background: @disabledColor;
          }
        }

        &:not([class*="ant-picker-cell-range-"]):hover:has(.disabled-date)::before {
          border-radius: 2px !important;
          color: @disabledTextHover;

          &::before {
            background: @disabledHoverColor;
          }
        }
      }
    }
  }

  // Light mode
  .ant-date-picker-custom-props(#f3f3f3, rgba(0, 0, 0, 0.3), #d5d5d5, rgba(0, 0, 0, 0.45));
  // Dark mode
  &[class$="dark-mode"] {
    .ant-date-picker-custom-props(rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.35));
  }

  .ant-picker-cell-in-range::before,
  .ant-picker-cell-range-start::before,
  .ant-picker-cell-range-end::before {
    background: var(--colorBgSelected) !important;
  }
  .ant-picker-cell-range-hover::before,
  .ant-picker-cell-range-hover-start::before,
  .ant-picker-cell-range-hover-end::before {
    background: var(--colorBgSelectedHover) !important;
  }
}

// Shift date picker selection to left if needed
.shift-date-picker-to-left .ant-picker-panel-container {
  margin-left: -80px;
}

/*** ANTD BUGS ****/

// Cropped displayed value in DatePicker
.ant-picker .ant-picker-input > input {
  border-radius: 0;
}

/**** views ****/

/* Bold labels and bold table headers */
.ant-form-item-label {
  font-weight: bold;
}

/********* FORM ITEMS ************/

/*** Input icons ***/

/* set the color of the icons so it's more visible*/
html {
  .ant-select-arrow,
  .ant-input-number-handler-up-inner,
  .ant-input-number-handler-down-inner,
  .anticon-swap-right {
    color: rgba(0, 0, 0, 0.8) !important;
  }

  &[class$="dark-mode"] {
    .ant-select-arrow,
    .ant-input-number-handler-up-inner,
    .ant-input-number-handler-down-inner,
    .anticon-swap-right {
      color: rgba(255, 255, 255, 0.7) !important;
    }
  }
}

/*** Border of all input fields ***/

[class^="ant-"][class*="-borderless"],
.carousel-arrow-button,
.quill {
  border: solid transparent 1px !important;
  transition: all 0.3s, height 0s;
}

// Borders on focus
[class^="ant-"][class*="-borderless"]:not([class*="-disabled"]):not([readonly]),
.quill {
  &:hover,
  &:focus,
  &:active,
  &:focus-within {
    border: solid 1px var(--noe-primary) !important;
  }

  // Wrapped inputs should not have borders (for password inputs or inputs with prefix/suffix)
  & > input {
    &:hover,
    &:focus,
    &:active,
    &:focus-within {
      border: solid 1px transparent !important;
    }
  }
}

// Fix no border radius for select fields
.ant-select.ant-select-borderless {
  border-radius: @borderRadius;
}

/*** carousel ***/
.carousel-arrow-button {
  display: block !important;
  color: black !important;
  border: 1px solid #d9d9d9 !important;
  background: white !important;
  min-width: 40px;
  z-index: 1;
  min-height: 40px;

  &:hover {
    border: solid 1px var(--noe-primary) !important;
    transform: scale(1.2);
  }

  & svg {
    vertical-align: middle;
  }
}

.carousel-container {
  margin: -5px 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  & > * {
    margin: 5px 30px;
  }
}

/* Steps in mobile view */
.ant-steps-vertical > .ant-steps-item .ant-steps-item-content {
  min-height: unset;
}

/************ TABLES *************/

// Pointer cursor if editable
.cursor-pointer {
  cursor: pointer;
}

// Removes the ugly border of the No data page in ListPage
.ant-table-placeholder > .ant-table-cell {
  border: none !important;
}

// Prevent column titles from wrapping on multiple lines. If they do, they get ellipsed instead.
// This prevents resizing glitches, in ListPage in particular.
th.ant-table-cell, // If no filtering at all, its the cell
  span.ant-table-column-title // If filters, it's in a span
{
  white-space: nowrap !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

// In Cards, Table headers have a white background
.ant-card .ant-table-wrapper .ant-table-thead > tr {
  > th,
  > td {
    background: var(--colorBgContainer) !important;
  }
}

// ListPage styles
.list-page {
  // Table headers have the same background as the page header.
  & .ant-table-wrapper .ant-table-thead > tr {
    > th,
    > td {
      background: var(--app-bg) !important;
    }
  }

  // Add some padding at the bottom to prevent the last item in the table body to be cut off by the pagination footer
  & .ant-table-body {
    padding-bottom: 38px !important;
  }

  // Move the pagination to the bottom in a fixed manner
  & .ant-table-wrapper .ant-table-pagination.ant-pagination {
    position: fixed;
    width: 100%;
    bottom: 0;
    border-top: 1px solid var(--colorBorderSecondary);
    background: var(--app-bg);
    height: 55px;
    padding-top: 14px;
    padding-bottom: 0;
    margin: 0 !important;
  }

  // On desktop, move the first column to the right to help readability
  @media @md-and-more {
    & .ant-table-thead > tr > th:first-child,
    .ant-table-row > .ant-table-cell:first-child {
      padding-left: 15px !important;
    }
  }
}

html {
  .ant-table-body-custom-props(@dangerBg, @dangerText, @updatedBg) {
    & .ant-table-tbody {
      // Table danger rows
      > tr.ant-table-row-danger > td {
        background: @dangerBg;
        font-style: italic !important;
        color: @dangerText;
      }

      // Table updated rows
      > tr.ant-table-row-updated > td {
        background: @updatedBg;
      }
    }
  }

  // Light mode
  .ant-table-body-custom-props(#fff5f5, darkred, #f3fff3);
  // Dark mode
  &[class$="dark-mode"] {
    .ant-table-body-custom-props(#1f0000, ff7e7e, #001);
  }
}

// Small pagination footer in tables
.ant-table-pagination.ant-pagination {
  margin: 10px 0;
}

/*** social icons ***/
.social-icons-container a {
  color: white !important;
  opacity: 0.4;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.8;
  }
}

/* Make select content appear on multiple lines if needed */
.ant-select-item-option-content {
  white-space: break-spaces;
  word-break: break-word;
}

/* Increase font size of the Agenda/List switcher in inscription-front */
#view-mode-changer-select .ant-segmented-item-label {
  font-size: 19px;
}
