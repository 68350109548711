/***************************************/
/*     CUSTOMIZE COLORS AND FONTS      */
/***************************************/

/**** COLOR DEFINITIONS ****/

@noe-bg: #000a2e;
@app-bg: #f8f8f8;
@text: #000000;
@noe-primary: #3775e1;
@noe-accent-1: #000bff;
@noe-accent-2: #7fffbb;

@colorBgContainer: #ffffff;
@colorBgElevated: #fafafa;
@colorBgLayout: #f8f8f8;
@colorBorder: #d0d0d0;
@colorBorderSecondary: #e3e3e3;
@colorBgSelected: lighten(@noe-primary, 37%);
@colorBgSelectedHover: lighten(@noe-primary, 30%);

@dark-text: rgba(255, 255, 255, 0.85);
@dark-colorPrimary: #4876c7;
@dark-colorBgContainer: #090909;
@dark-colorBgElevated: #1b1b1b;
@dark-colorBgLayout: #181818;
@dark-colorBorder: #4b4b4b;
@dark-colorBorderSecondary: #3d3d3d;
@dark-colorBgSelected: darken(@dark-colorPrimary, 43%);
@dark-colorBgSelectedHover: darken(@dark-colorPrimary, 37%);

html {
  --noe-bg: @noe-bg;
  --app-bg: @app-bg;
  --text: @text;
  --noe-primary: @noe-primary;
  --noe-accent-1: @noe-accent-1;
  --noe-accent-2: @noe-accent-2;
  --noe-accent-1-90: mix(@noe-accent-1, @noe-accent-2, 90%);
  --noe-accent-1-85: mix(@noe-accent-1, @noe-accent-2, 85%);
  --noe-accent-2-85: mix(@noe-accent-2, @noe-accent-1, 85%);
  --noe-gradient: linear-gradient(135deg, var(--noe-accent-1-90), 40%, var(--noe-accent-2));
  --colorBgContainer: @colorBgContainer;
  --colorBgElevated: @colorBgElevated;
  --colorBorder: @colorBorder;
  --colorBorderSecondary: @colorBorderSecondary;
  --colorBgSelected: @colorBgSelected;
  --colorBgSelectedHover: @colorBgSelectedHover;
  &[class$="dark-mode"] {
    --noe-bg: @dark-colorBgElevated;
    --app-bg: @dark-colorBgLayout;
    --text: @dark-text;
    --noe-primary: @dark-colorPrimary;
    --colorBgContainer: @dark-colorBgContainer;
    --colorBgElevated: @dark-colorBgElevated;
    --colorBorder: @dark-colorBorder;
    --colorBorderSecondary: @dark-colorBorderSecondary;
    --colorBgSelected: @dark-colorBgSelected;
    --colorBgSelectedHover: @dark-colorBgSelectedHover;
    body {
      color: @dark-text; // Override the reboot.css color
    }
  }
}

// Utility for displaying a NOE background
.bg-noe-gradient {
  background: linear-gradient(135deg, var(--noe-accent-1-90), 40%, var(--noe-accent-2));
}

/*** TITLE FONT DEFINITION ****/

// Import the Google fonts stored locally

/* latin-ext */
@font-face {
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(fonts/JosefinSansLatinExt.woff2) format("woff2");
  //src: url(https://fonts.gstatic.com/s/josefinsans/v25/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_ArQbMhhLzTs.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(fonts/JosefinSansLatin.woff2) format("woff2");
  //src: url(https://fonts.gstatic.com/s/josefinsans/v25/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_ArQbMZhLw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Josefin Sans", Arial, sans-serif;
}

/**** GLOBAL THEME DEFINITIONS ****/
@borderRadius: 10px;
